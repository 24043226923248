import React from 'react';
import cloud from '../../assets/cloud.png';
import mine from '../../assets/mine3.png';
import { MdOutlineWavingHand } from "react-icons/md";
import {ReactTyped as Typed } from 'react-typed'; // Or try `ReactTyped` if `Typed` does not work
import './styles.css';

const Welecome = ({ scrollToSection, resumeRef }) => {
    return (
        <div className='max-w-6xl mx-auto flex flex-col md:flex-col lg:flex-row justify-between items-center overflow-hidden'>
            {/* Text Section */}
            <div className='w-full flex justify-center items-center py-24 lg:py-0'>
                <div className='ml-8 md:ml-0 '>
                    <h1 className='text-6xl text-white md:w-full mx-auto'>
                        <div className='flex flex-col md:flex-row w-full md:gap-3'>
                            Hey <MdOutlineWavingHand className='text-yellow-600 hidden md:block' />
                            I'm Salman
                        </div>
                    </h1>
                    <div className='mt-4 w-72'>
                        <Typed
                            className='text-3xl text-white'
                            strings={['Professional Coder', 'Web Developer']}
                            typeSpeed={50}
                            backSpeed={50}
                            loop
                        />
                    </div>
                    <button onClick={() => scrollToSection(resumeRef)} className='btn bg-[#ff014f] border-none px-10 rounded-full  mt-8 text-base md:text-xl  text-white w-full  lg:w-1/2'>My Resume</button>
                </div>
            </div>

            {/* Image Section with Background Image */}
            <div className='relative w-full flex justify-center items-center'>
                {/* Background Image with Opacity */}
                <div
                    className='absolute inset-0 bg-cover bg-center opacity-30'
                    style={{ backgroundImage: `url(${cloud})` }}
                ></div>

                {/* Foreground Image */}
                <div className='relative  flex justify-center items-center'>
                    <img className='mx-auto lg:max-w-[500px]' src={mine} alt='mine' />
                </div>
            </div>
        </div>
    );
};

export default Welecome;
