import {
    createBrowserRouter,
 
  } from "react-router-dom";
import App from "../App";
import Home from "../pages/home/Home";
import Constraction from "../pages/constraction/Constraction";
import Dashboard from "../Dashboard";
import Login from "../pages/login/Login";
import Register from "../pages/register/Register";
import Blogs from "../pages/blogs/Blogs";
import News from "../pages/blogs/news/News";
import Post from "../pages/dashBoard/post/Post";
import Consultation from "../pages/consultation/Consultation";

const router = createBrowserRouter([
    {
      path: "/",
      element: <App/>,
      children:[
        {
            path:"/",
            element:<Home/>
        },
        {
          path:"/cons",
          element:<Constraction/>
        },
        {
          path:"login",
          element:<Login/>
        },
        {
          path:"register",
          element:<Register/>
        },
        {
          path:"/blog/:id",
          element:<Blogs/>
        },
        {
          path:"news",
          element:<News/>
        },
        {
          path:'consultation',
          element:<Consultation/>
        }
      ]
    },
    {
      path:"dashboard",
      element:<Dashboard/>,
      children:[
        {
          path:"dashboard",
          element:<Dashboard/>
        },
        {
          path:"/dashboard/post",
          element:<Post/>
        }
      ]
    }
  ]);
  export default router;