import React, { forwardRef } from 'react';
import aboutMe from '../../assets/LinkedIn-Headshot.png';
import './styles.css'

const AboutMe =forwardRef ((props,ref) => {
    
    return (
        <div className='max-w-6xl lg:max-w-5xl xl:max-w-6xl 2xl:max-w-7xl mx-auto text-white px-4 lg:px-8 mt-48 overflow-x-hidden'>
            <div className='relative flex flex-col lg:flex-row items-center justify-between w-full overflow-x-hidden py-10 lg:py-0'>
                <div className=' w-[200px] lg:w-[500px] h-[200px] lg:h-[500px] bg-[#f90946] rounded-full relative overflow-hidden '>
                    <img
                        src={aboutMe}
                        alt='me'
                        className='w-full h-full object-cover absolute top-0 left-0'
                    />
                </div>
                <div className='w-full lg:w-1/2'>
                    <p className='text-gradient text-2xl font-extrabold'>ABOUT ME</p>
                    <p className='text-base lg:text-4xl w-80 font-semibold mt-6'>I can develop
                    that help people</p>
                    <p className='break-all mt-8'>Hi, my name is Salman Haider, and I've been deeply involved in web development for over 7 years. With extensive experience across both front-end and back-end technologies, I specialize in transforming ideas into fully functional, responsive, and dynamic websites. Whether it's creating a custom platform from scratch or optimizing existing solutions, I focus on delivering unique, high-quality results tailored to meet each client's specific needs.</p>
                </div>
            </div>
        </div>
    );
});

export default AboutMe;
