import React from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import UseAxiosPublic from '../../hook/UseAxiosPublic';
import emailjs from 'emailjs-com';

const Consultation = () => {
    const { register, handleSubmit, formState: { errors } } = useForm();
    const axiosPublic = UseAxiosPublic();

    const onSubmit = (data) => {
        console.log(data);
        // Handle form submission logic here
        axiosPublic.post('/bookings', data)
            .then(res => {
                console.log(res.data);

                if (res.data.insertedId) {
                    emailSend(data);
                    alert('Booking successful. You will be notified via email.');
                }
            });
    };

    const emailSend = (data) => {
        console.log(data);
        const emailInfo = {
            name: data.name,
            email: data.email,
            message: data.textarea
        };
        emailjs.send('service_3d7wl6s', 'template_oxqog3a', emailInfo, 'IQMDrJD8MHbEC2oKr')
            .then((response) => {
                console.log('Email sent successfully:', response.status, response.text);
            })
            .catch((error) => {
                console.error('Error sending email:', error);
            });
    };

    return (
        <div className="h-screen flex flex-col items-center justify-center bg-gradient-to-r from-slate-200 to-slate-600 p-4">
            {/* Home button - Will be placed below the form on small screens */}
            <div className="mt-4 mb-2 md:mb-0 w-full flex justify-center md:absolute md:top-5 md:right-5 md:w-auto md:mt-0">
                <Link to='/'>
                    <button
                        className='p-3 w-[150px] text-white font-semibold rounded-lg border-2 border-transparent hover:bg-white hover:text-black transition duration-300 home-button cursor-pointer z-20'
                    >
                        Home
                    </button>
                </Link>
            </div>
            <div className="w-full">
                <form
                    onSubmit={handleSubmit(onSubmit)}
                    className="bg-gradient-to-r from-slate-500 via-slate-600 to-slate-800 p-8 rounded-lg shadow-md max-w-lg w-full mx-auto"
                >
                    <h2 className="text-2xl font-bold mb-6 text-center text-white">Get Free Consultation</h2>

                    <div className="mb-4">
                        <label className="block text-gray-700">Name</label>
                        <input
                            type="text"
                            {...register('name', { required: true })}
                            className="mt-1 p-2 border w-full rounded bg-slate-300"
                        />
                        {errors.name && <span className="text-red-500">Name is required</span>}
                    </div>

                    <div className="mb-4">
                        <label className="block text-gray-700">Email</label>
                        <input
                            type="email"
                            {...register('email', { required: true })}
                            className="mt-1 p-2 border w-full rounded bg-slate-300"
                        />
                        {errors.email && <span className="text-red-500">Email is required</span>}
                    </div>

                    <div className="mb-4">
                        <label className="block text-gray-700">Address</label>
                        <input
                            type="text"
                            {...register('address')}
                            className="mt-1 p-2 border w-full rounded bg-slate-300"
                        />
                    </div>

                    <div className="mb-4">
                        <label className="block text-gray-700">Topic</label>
                        <input
                            type="text"
                            {...register('topic', { required: true })}
                            className="mt-1 p-2 border w-full rounded bg-slate-300"
                        />
                        {errors.topic && <span className="text-red-500">Topic is required</span>}
                    </div>

                    <div className="mb-6">
                        <label className="block text-gray-700">Your Message</label>
                        <textarea
                            {...register('textarea', { required: true })}
                            className="mt-1 p-2 border w-full rounded h-32 bg-slate-300"
                        />
                        {errors.textarea && <span className="text-red-500">Message is required</span>}
                    </div>

                    <button type="submit" className="w-full bg-blue-500 text-white p-2 rounded hover:bg-blue-600 transition">
                        Submit
                    </button>
                </form>
            </div>

        
        </div>
    );
};

export default Consultation;
