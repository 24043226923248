import { DiJavascript } from "react-icons/di";
import { TbSeo } from "react-icons/tb";
import React, { useEffect, useState } from 'react';
import { FaReact } from "react-icons/fa";

const ProgressBar = ({ percentage }) => {
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        const timer = setInterval(() => {
            setProgress((oldProgress) => {
                const newProgress = oldProgress + 1;
                if (newProgress >= percentage) {
                    clearInterval(timer);
                    return percentage;
                }
                return newProgress;
            });
        }, 10); // Adjust the speed of the animation here
        return () => clearInterval(timer);
    }, [percentage]);

    return (
        <div className="w-full bg-gray-200 rounded-full h-4 overflow-hidden">
            <div
                className="bg-yellow-500 h-full rounded-full"
                style={{ width: `${progress}%` }}
            ></div>
        </div>
    );
};

const Skills = () => {
    return (
        <div>
            <div className="mb-4 flex gap-16 items-center justify-between w-full">
                <FaReact className='text-4xl text-yellow-500' />
                <p className='text-xl mt-2 w-1/4'>Frontend Development</p>
                <p className='w-1/2 flex items-center gap-4'>95%<ProgressBar percentage={95} /></p>
            </div>
            <div className="mb-4 flex gap-16 items-center justify-between w-full">
                <DiJavascript  className='text-4xl text-yellow-500' />
                <p className='text-xl mt-2 w-1/4'>Backend Development</p>
                <p className='w-1/2 flex items-center gap-4'>90%<ProgressBar percentage={90} /></p>
            </div>
            <div className="mb-4 flex gap-16 items-center justify-between w-full">
                <TbSeo className='text-4xl text-yellow-500' />
                <p className='text-xl mt-2 w-1/4'>SEO</p>
                <p className='w-1/2 flex items-center gap-4'>75%<ProgressBar percentage={75} /></p>
            </div>
            
        </div>
    );
};

export default Skills;
