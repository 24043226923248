import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { FaCheck } from 'react-icons/fa';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: {
        xs: '90%', // Small screens (mobile)
        sm: '80%', // Medium screens (tablet)
        md: '70%', // Larger screens (desktop)
        lg: '60%', // Extra large screens
        xl: 1200,  // Ultra-wide screens
    },
    bgcolor: '#040836',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    maxHeight: '90vh',
    overflowY: 'auto',
};

// Custom scroll bar styles
const scrollStyle = {
    '&::-webkit-scrollbar': {
        width: '8px',
    },
    '&::-webkit-scrollbar-thumb': {
        backgroundColor: 'red',
        borderRadius: '8px',
    },
    '&::-webkit-scrollbar-track': {
        backgroundColor: 'white',
        borderRadius: '8px',
    },
};

// Glass effect style for the button
const glassEffect = {
    background: 'rgba(1, 43, 69)',
    backdropFilter: 'blur(10px)',
    border: '1px solid rgba(255, 255, 255, 0.18)',
    color: 'white',
    width: '100%',
    textAlign: 'left',
    padding: '10px',
    borderRadius: '8px',
    cursor: 'pointer',
    marginBottom: '10px'
};
const glassEffectService = {
    background: 'rgba(1, 43, 69)',
    backdropFilter: 'blur(10px)',
    border: '1px solid rgba(255, 255, 255, 0.18)',
    color: 'white',
    width: '100%',
    textAlign: 'left',
    padding: '10px',
    borderRadius: '8px',
    cursor: 'pointer',
    marginBottom: '10px'
};

const ServiceModal = ({ open, handleClose, service }) => {
    const [visibleAnswers, setVisibleAnswers] = React.useState({});

    const toggleAnswer = (index) => {
        setVisibleAnswers((prev) => ({
            ...prev,
            [index]: !prev[index],
        }));
    };

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={{ ...style, ...scrollStyle }}>
                <img src={service.image} alt='modal' className="w-full h-auto mb-4" />
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    <p className='text-2xl text-white font-bold'>{service.title}</p>
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    <p className='text-white'>{service.details}</p>
                </Typography>
                <div className='flex mt-4'>
                    <div className='w-[30%]'>
                        <p className='text-xl mt-2 mb-2 text-white'>Include Services</p>
                        <p className='text-white'>
                            {service.included_services.map(include =>
                                <li key={include} className='list-none'>
                                    <p className='flex items-center gap-2'>
                                        <FaCheck className='text-red-700' /> {include}
                                    </p>
                                </li>
                            )}
                        </p>
                    </div>
                    <div className='w-[70%]'>
                        <p className='text-white text-xl mb-2 mt-2'>Challenges</p>
                        <p className='text-white'>{service.chall_description}</p>
                    </div>
                </div>
                <div className='text-white mt-6 mb-4'>
                    <p className='text-xl mb-4'>What we do?</p>
                    <p>{service.what_we_do_description}</p>
                </div>
                <div className='text-white'>
                    <p className='text-xl mb-4'>Common Questions for this Project</p>
                    {service.faq.map((item, index) => (
                        <div key={index}>
                            <button
                                style={glassEffect}
                                onClick={() => toggleAnswer(index)}
                            >
                                {item.question}
                            </button>
                            {visibleAnswers[index] && (
                                <p className='text-white mt-2 mb-4 pl-4'>{item.answer}</p>
                            )}
                        </div>
                    ))}
                </div>
                <div>
                    <p className='text-xl font-bold text-white mb-6 mt-4'>Popular Services</p>
                    <div className='grid grid-cols-2 gap-4'>
                        {
                            service.popular_services.map((popular) =>
                                <div key={popular} style={glassEffectService} >
                                    <div className='text-xl mb-4 '> {popular.service}</div>
                                    {popular.description}
                                </div>
                            )
                        }
                    </div>
                </div>
            </Box>
        </Modal>
    );
};

export default ServiceModal;
