import React, { useEffect, useState } from 'react';
import sun from '../../assets/10.png';
import CountUp from 'react-countup';
import RecentModal from '../Modal/RecentModal';

const RecentWork = () => {
    const [recentWorks, setRecentWorks] = useState([]);
    const [selectedItem, setSelectedItem] = useState(null);
    const [showModal, setShowModal] = useState(false);

    const handleSelectedItem=(item)=>{
        setSelectedItem(item);
        setShowModal(true);
    }

    const closeModal=()=>{
        setShowModal(false);
        setSelectedItem(null);
    }

    useEffect(() => {
        fetch('recent.json')
            .then(res => res.json())
            .then(data => setRecentWorks(data));
    }, []);

    return (
        <div className='max-w-6xl lg:max-w-5xl xl:max-w-6xl 2xl:max-w-7xl mx-auto text-white px-4 lg:px-8 overflow-hidden'>
            <div className='flex flex-col lg:flex-row justify-between items-center'>
                <div>
                    <img className='-ml-16' src={sun} alt='sun' />
                    <p className='text-4xl font-bold max-w-[450px]'>
                        Look at my portfolio and give me your feedback
                    </p>
                </div>
                <div>
                    <div className="stats stats-vertical lg:stats-horizontal shadow-none bg-transparent text-white">
                        <div className="stat">
                            <div className="stat-title text-white">Completed Projects</div>
                            <div className="stat-value">
                                <CountUp start={0} end={175} duration={2.5} separator="," />
                            </div>
                            <div className="stat-desc">Jan 1st - Feb 1st</div>
                        </div>

                        <div className="divider divider-horizontal"></div>
                        <div className="stat text-white">
                            <div className="stat-title text-white">Positive Rating</div>
                            <div className="stat-value text-white">
                                <CountUp start={0} end={96} duration={2.5} separator="," />%
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mt-8'>
                {
                    recentWorks.map((item, index) =>
                        <div onClick={()=>handleSelectedItem(item)}  key={index} className='mx-auto'>
                            <img className='rounded-lg shadow-lg h-72 w-[350px]' src={item.image} alt={item.title} />
                        </div>
                    )
                }
            </div>
            {
                selectedItem && (
                    <RecentModal open={showModal} close={closeModal} item={selectedItem}/>
                )
            }
        </div>
    );
};

export default RecentWork;
