import React, { useRef } from 'react';
import bgImg from '../../assets/banner-2.jpg';
import logo from '../../assets/t.png';
import { IoHomeOutline, IoSettingsOutline } from "react-icons/io5";
import { TfiBag } from "react-icons/tfi";
import { FaRegUser, FaPlayCircle } from "react-icons/fa";
import { LiaFileInvoiceSolid } from "react-icons/lia";
import { TbMessages } from "react-icons/tb";
import './styles.css';
import Welecome from '../../components/wlecome/Welecome';
import Services from '../../components/services/Services';
import RecentWork from '../../components/recentWork/RecentWork';
import AboutMe from '../../components/aboutMe/AboutMe';
import Resume from '../../components/resume/Resume';
import Pricing from '../../components/pricing/Pricing';
import CustomerReview from '../../components/customerReview/CustomerReview';
import FreelanceHire from '../../components/freelanceHire/FreelanceHire';
import Blog from '../../components/blog/Blog';
import Contact from '../../components/contact/Contact';
import Footer from '../../components/footer/Footer';
import { SiSimplelogin } from "react-icons/si";
import { Link, useNavigate } from 'react-router-dom';
import UseAuth from '../../hook/UseAuth';
import { RiLogoutCircleRFill } from "react-icons/ri";
import { MdManageAccounts } from "react-icons/md";
import { FaBlogger } from "react-icons/fa";
import { Helmet } from 'react-helmet-async';

const Home = () => {
    // Create refs for each section
    const homeRef = useRef(null);
    const servicesRef = useRef(null);
    const portfolioRef = useRef(null);
    const aboutRef = useRef(null);
    const resumeRef = useRef(null);
    const contactRef = useRef(null);
    const hireMeRef = useRef(null);
    const priceRef = useRef(null);
    const { user, logOutUser } = UseAuth();
    const navigate = useNavigate()


    // Function to scroll to the specified section
    const scrollToSection = (ref) => {

        if (ref.current) {
            ref.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const handleLogOut = () => {
        logOutUser()
            .then(res => {
                console.log(res)
                navigate('/login')
            })
    }


    return (
        <div className='min-h-screen overflow-hidden bg-fixed' style={{ backgroundImage: `url(${bgImg})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
            <div>
                <Helmet>
                    <title>{`Top Orion`}</title>
                    <meta name="description" content="Custom website development" />
                    <meta name="keywords" content="custom website development,fitness website,fitness websites,gym website,web development agency" />
                    <meta name="author" content="Top Orion" />
                </Helmet>
            </div>
            <div className='md:w-[112px] w-20 h-screen bg-slate-900 fixed flex flex-col justify-between'>
                <div className='h-24 bg-[#ff014f]'>
                    <img className='w-full mx-auto' src={logo} alt='logo' />
                </div>
                <div className='flex-1 overflow-y-auto'>
                    <div className='border-b border-b-slate-600 py-4'>
                        <div
                            className='relative group cursor-pointer'
                            onClick={() => scrollToSection(homeRef)}
                        >
                            <IoHomeOutline className='text-2xl md:text-3xl text-white mx-auto mb-2 transition-all duration-300 group-hover:translate-x-9 group-hover:opacity-0 ' />
                            <div className='absolute left-0 top-0 flex items-center h-full transform -translate-x-full opacity-0 group-hover:opacity-100 group-hover:translate-x-8 transition-all duration-300'>
                                <span className='text-white text-sm md:text-xl'>Home</span>
                            </div>
                        </div>
                    </div>
                    <div className='border-b border-b-slate-600 py-4'>
                        <div
                            className='relative group cursor-pointer'
                            onClick={() => scrollToSection(servicesRef)}
                        >
                            <IoSettingsOutline className='text-2xl md:text-3xl text-white mx-auto mb-2 transition-all duration-300 group-hover:translate-x-9 group-hover:opacity-0' />
                            <div className='absolute left-0 top-0 flex items-center h-full transform -translate-x-full opacity-0 group-hover:opacity-100 group-hover:translate-x-8 transition-all duration-300'>
                                <span className='text-white text-sm md:text-xl'>Services</span>
                            </div>
                        </div>
                    </div>
                    <div className='border-b border-b-slate-600 py-4'>
                        <div
                            className='relative group cursor-pointer'
                            onClick={() => scrollToSection(portfolioRef)}
                        >
                            <TfiBag className='text-2xl md:text-3xl text-white mx-auto mb-2 transition-all duration-300 group-hover:translate-x-9 group-hover:opacity-0' />
                            <div className='absolute left-0 top-0 flex items-center h-full transform -translate-x-full opacity-0 group-hover:opacity-100 group-hover:translate-x-8 transition-all duration-300'>
                                <span className='text-white text-sm md:text-xl'>Portfolio</span>
                            </div>
                        </div>
                    </div>
                    <div className='border-b border-b-slate-600 py-4'>
                        <div
                            className='relative group cursor-pointer'
                            onClick={() => scrollToSection(aboutRef)}
                        >
                            <FaRegUser className='text-2xl md:text-3xl text-white mx-auto mb-2 transition-all duration-300 group-hover:translate-x-9 group-hover:opacity-0' />
                            <div className='absolute left-0 top-0 flex items-center h-full transform -translate-x-full opacity-0 group-hover:opacity-100 group-hover:translate-x-8 transition-all duration-300'>
                                <span className='text-white text-sm md:text-xl'>About</span>
                            </div>
                        </div>
                    </div>
                    <div className='border-b border-b-slate-600 py-4'>
                        <div
                            className='relative group cursor-pointer'
                            onClick={() => scrollToSection(resumeRef)}
                        >
                            <LiaFileInvoiceSolid className='text-2xl md:text-3xl text-white mx-auto mb-2 transition-all duration-300 group-hover:translate-x-9 group-hover:opacity-0' />
                            <div className='absolute left-0 top-0 flex items-center h-full transform -translate-x-full opacity-0 group-hover:opacity-100 group-hover:translate-x-8 transition-all duration-300'>
                                <span className='text-white text-sm md:text-xl'>Resume</span>
                            </div>
                        </div>
                    </div>
                    <div className='border-b border-b-slate-600 py-4'>
                        <div
                            className='relative group cursor-pointer'
                            onClick={() => scrollToSection(contactRef)}
                        >
                            <TbMessages className='text-2xl md:text-3xl text-white mx-auto mb-2 transition-all duration-300 group-hover:translate-x-9 group-hover:opacity-0' />
                            <div className='absolute left-0 top-0 flex items-center h-full transform -translate-x-full opacity-0 group-hover:opacity-100 group-hover:translate-x-8 transition-all duration-300'>
                                <span className='text-white text-sm md:text-xl'>Contact</span>
                            </div>
                        </div>
                    </div>

                    <div className='border-b border-b-slate-600 py-4'>
                        {user ? (
                            <Link onClick={handleLogOut}>
                                <div className='relative group cursor-pointer'>
                                    <RiLogoutCircleRFill className='text-2xl md:text-3xl text-white mx-auto mb-2 transition-all duration-300 group-hover:translate-x-9 group-hover:opacity-0' />
                                    <div className='absolute left-0 top-0 flex items-center h-full transform -translate-x-full opacity-0 group-hover:opacity-100 group-hover:translate-x-8 transition-all duration-300'>
                                        <span className='text-white text-sm md:text-xl'>Log Out</span>
                                    </div>
                                </div>
                            </Link>
                        ) : (
                            <Link to="/login">
                                <div className='relative group cursor-pointer'>
                                    <SiSimplelogin className='text-2xl md:text-3xl text-white mx-auto mb-2 transition-all duration-300 group-hover:translate-x-9 group-hover:opacity-0' />
                                    <div className='absolute left-0 top-0 flex items-center h-full transform -translate-x-full opacity-0 group-hover:opacity-100 group-hover:translate-x-8 transition-all duration-300'>
                                        <span className='text-white text-sm md:text-xl'>Login</span>
                                    </div>
                                </div>
                            </Link>
                        )}
                    </div>
             
                    <div className='py-4'>
                        <Link to='/news'>
                            <div className='relative group cursor-pointer'>
                                <FaBlogger className='text-2xl md:text-3xl text-white mx-auto mb-2 transition-all duration-300 group-hover:translate-x-9 group-hover:opacity-0' />
                                <div className='absolute left-0 top-0 flex items-center h-full transform -translate-x-full opacity-0 group-hover:opacity-100 group-hover:translate-x-8 transition-all duration-300'>
                                    <span className='text-white text-sm md:text-xl'>Blog</span>
                                </div>
                            </div>
                        </Link>
                    </div>
                </div>
            </div>

            <div className='ml-[112px]'>
                <div ref={homeRef}>
                    <Welecome scrollToSection={scrollToSection} resumeRef={resumeRef} contactRef={contactRef} priceRef={priceRef} />
                </div>
                <div ref={servicesRef}>
                    <Services />
                </div>

                <div ref={portfolioRef}>
                    <RecentWork />
                </div>
                <div ref={aboutRef}>
                    <AboutMe />
                </div>
                <div ref={resumeRef}>
                    <Resume />
                </div>
                <div ref={priceRef}>
                    <Pricing scrollToSection={scrollToSection} contactRef={contactRef} />
                </div>
                <CustomerReview />
                <div ref={hireMeRef}>
                    <FreelanceHire scrollToSection={scrollToSection} contactRef={contactRef} />
                </div>
                <Blog />
                <div ref={contactRef}>
                    <Contact />
                </div>
                <Footer />
            </div>
        </div>
    );
};

export default Home;
