import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: {
        xs: '90%', // Small screens (mobile)
        sm: '80%', // Medium screens (tablet)
        md: '70%', // Larger screens (desktop)
        lg: '60%', // Extra large screens
        xl: 1200,  // Ultra-wide screens
    },
    bgcolor: '#040836',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    maxHeight: '90vh',
    overflowY: 'auto',
};

// Custom scroll bar styles
const scrollStyle = {
    '&::-webkit-scrollbar': {
        width: '8px',
    },
    '&::-webkit-scrollbar-thumb': {
        backgroundColor: 'red',
        borderRadius: '8px',
    },
    '&::-webkit-scrollbar-track': {
        backgroundColor: 'white',
        borderRadius: '8px',
    },
};
const RecentModal = ({open,close,item}) => {
    return (
        <Modal
        open={open}
        onClose={close}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
    >
        <Box sx={{ ...style, ...scrollStyle }}>
            <img src={item.image} alt='modal' className="w-full h-auto mb-4" />
            <Typography id="modal-modal-title" variant="h6" component="h2">
                <p className='text-2xl text-white font-bold'>{item.title}</p>
            </Typography>
        
      
          
          
            
        </Box>
    </Modal>
    );
};

export default RecentModal;