import React from 'react';

const Education = () => {
    return (
        <div className='grid grid-cols-1 md:grid-cols-2 gap-6 '>
            <div>
                <p>Advanced web development</p>
                <p>Hashcode Plus</p>
                <p className='bg-red-500 text-white w-20 mb-2'>2022-2023</p>
                <p>Focused training in advanced web development, covering modern front-end and back-end technologies to build scalable and secure web applications. The program prepares professionals for senior roles in the tech industry.</p>
            </div>
            <div>
                <p>FSWD(Full stack web development)</p>
                <p>Hashcode Plus</p>
                <p className='bg-red-500 text-white w-20 mb-2'>2018-2022</p>
                <p>Comprehensive training in full stack web development, covering both front-end and back-end technologies, to equip professionals with the skills required to build and maintain complex, dynamic websites and applications. The program emphasizes practical, hands-on experience in modern web technologies, preparing individuals for diverse roles in the tech industry.</p>
            </div>
            <div>
                <p>MBA</p>
                <p>Primeasia University</p>
                <p className='bg-red-500 text-white w-20 mb-2'>2014-2016</p>
                <p>Advanced education in business management, designed to deepen understanding of complex business strategies and enhance leadership capabilities. The program prepares professionals to take on senior management roles, with a focus on strategic decision-making, problem-solving, and innovation in the global business landscape.</p>
            </div>
            <div>
                <p>BBA</p>
                <p>Primeasia University</p>
                <p className='bg-red-500 text-white w-20 mb-2'>2011-2024</p>
                <p>A broad-based education in business principles and practices, focusing on developing strong analytical, managerial, and leadership skills. This degree prepares graduates to effectively manage and lead organizations in various sectors of the economy, equipping them with the knowledge to excel in competitive business environments.</p>
            </div>
        </div>
    );
};

export default Education;