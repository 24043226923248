// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration

const firebaseConfig = {

  apiKey: "AIzaSyCaTgLazNoGDHMstbA-s8ZAQq_vOHhwpAc",
  authDomain: "topportfolio-bee6d.firebaseapp.com",
  projectId: "topportfolio-bee6d",
  storageBucket: "topportfolio-bee6d.appspot.com",
  messagingSenderId: "1055045947279",
  appId: "1:1055045947279:web:8cb0a1b63bb90dec6dc5fa"

};


// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
export default auth;