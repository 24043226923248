import React from 'react';
import sun from '../../assets/10.png';
import { HiCodeBracketSquare } from "react-icons/hi2";
import { GiCheckMark } from "react-icons/gi";
import { TbSeo } from "react-icons/tb";
import ani from '../../assets/ani.webp'



const Pricing = ({scrollToSection,contactRef}) => {
    return (
        <div className='mt-28 max-w-[95%] lg:max-w-6xl mx-auto px-4 lg:px-8 overflow-hidden'>
            <div className='flex flex-col lg:flex-row gap-8 lg:gap-16 justify-between items-center max-w-full '>
                {/* Left Section */}
                <div className=' w-full lg:w-[60%] -mt-16 relative  '>
                    <div className='flex flex-col items-start ml-4'>
                        <p className='text-xl py-8 text-gradient font-bold'>Pricing</p>
                        <span className='relative'>
                            <img className='w-10 transform -translate-y-16 -translate-x-7' src={sun} alt='sun' />
                        </span>
                        <p className='text-2xl lg:text-3xl font-bold text-white mt-4 lg:-mt-16'>The best pricing plans to get your best</p>
                        <div className='w-full md:w-[70%] lg:w-[60%] px-6 md:px-12 py-12 bg-[#040836] text-white mt-10 rounded-2xl '>
                            <p className='text-2xl md:text-3xl font-bold'>Save up to <span className='text-red-700'>50%</span> with your every order</p>
                        </div>
                    </div>
                    <div className='absolute top-20 -left-20 animate-bounce'>
                        <img className='w-40 md:w-48 lg:w-60' src={ani} alt='ani'/>
                    </div>
                </div>

                {/* Right Section */}
                <div className='flex flex-col justify-between gap-4 w-full lg:w-[40%] space-y-6 '>
                    <div className='bg-[#040836] h-auto w-full p-8 text-white card bg-opacity-10 backdrop-filter backdrop-blur-md rounded border gap-8 border-opacity-30 border-gray-200 flex flex-col justify-between relative '>
                        <div>
                            <p className='text-xl lg:text-2xl font-bold text-white'>website development</p>
                        </div>
                        <p className='absolute top-8 right-8 text-xl font-bold'>$1000</p>
                        <div className='flex flex-col '>
                            <p className='flex gap-2'><GiCheckMark className='text-red-600' /> Frontend Development</p>
                            <p className='flex gap-2'><GiCheckMark className='text-red-600' /> Backend Development</p>
                            <p className='flex gap-2'><GiCheckMark className='text-red-600' /> Responsive Design</p>
                            <button onClick={()=>scrollToSection(contactRef)} className='mt-2  bg-[#ff014f] hover:bg-sky-400   lg:w-1/3 py-3  text-center rounded-3xl'>Let's Talk</button>
                        </div>
                        <HiCodeBracketSquare className='hidden lg:block absolute bottom-8 right-8 text-5xl text-blue-500' />
                    </div>
                    <div className='bg-[#040836] h-auto w-full p-8 text-white card bg-opacity-10 backdrop-filter backdrop-blur-md rounded border gap-8 border-opacity-30 border-gray-200 flex flex-col justify-between relative'>
                        <div>
                            <p className='text-xl lg:text-2xl font-bold text-white'>SEO</p>
                        </div>
                        <p className='absolute top-8 right-8 lg:text-xl font-bold'>$500/M</p>
                        <div className='flex flex-col'>
                            <p className='flex gap-2'><GiCheckMark className='text-red-600' /> On-page seo</p>
                            <p className='flex gap-2'><GiCheckMark className='text-red-600' /> Off-page seo</p>
                            <p className='flex gap-2'><GiCheckMark className='text-red-600' /> Keywords research</p>
                            <button onClick={()=>scrollToSection(contactRef)} className='mt-2  bg-[#ff014f] hover:bg-sky-400 lg:w-1/3 py-3  text-center rounded-3xl'>Let's Talk</button>
                        </div>
                        <TbSeo className='hidden lg:block absolute bottom-8 right-8 text-5xl text-blue-500' />
                    </div>
                </div>
            </div>

         
        </div>
    );
};

export default Pricing;
