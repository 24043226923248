import React from 'react';
import logo1 from '../../assets/airtm.png';
import logo2 from '../../assets/paysafe.png';
import logo3 from '../../assets/wallmart.png';
import logo4 from '../../assets/moile.png';
import logo5 from '../../assets/quick.png';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import review1 from '../../assets/review1.png'
import review2 from '../../assets/review2.png'
import review3 from '../../assets/review3.png'

import { useKeenSlider } from "keen-slider/react"
import "keen-slider/keen-slider.min.css"
import "./styles.css"

const CustomerReview = () => {

    const [sliderRef] = useKeenSlider(
        {
            loop: true,
        },
        [
            (slider) => {
                let timeout;
                let mouseOver = false;
                function clearNextTimeout() {
                    clearTimeout(timeout);
                }
                function nextTimeout() {
                    clearTimeout(timeout);
                    if (mouseOver) return;
                    timeout = setTimeout(() => {
                        slider.next();
                    }, 2000);
                }
                slider.on("created", () => {
                    slider.container.addEventListener("mouseover", () => {
                        mouseOver = true;
                        clearNextTimeout();
                    });
                    slider.container.addEventListener("mouseout", () => {
                        mouseOver = false;
                        nextTimeout();
                    });
                    nextTimeout();
                });
                slider.on("dragStarted", clearNextTimeout);
                slider.on("animationEnded", nextTimeout);
                slider.on("updated", nextTimeout);
            },
        ]
    );

    return (
        <div className='mt-28 max-w-6xl mx-auto px-4 lg:px-8 overflow-hidden'>
            {/* Logos Section */}
            <div className='grid grid-cols-3 lg:grid-cols-5 justify-between lg:mt-12 py-10 lg:py-0 text-slate-300 opacity-40'>
                <img className='w-16 lg:w-20' src={logo1} alt='' />
                <img className='w-16 lg:w-20' src={logo2} alt='' />
                <img className='w-16 lg:w-20' src={logo3} alt='' />
                <img className='w-16 lg:w-20' src={logo4} alt='' />
                <img className='w-16 lg:w-20' src={logo5} alt='' />
            </div>

            {/* Divider */}
            <div className='relative mt-6'>
                <Divider sx={{ borderColor: 'gray', opacity: 0.75 }} />
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        width: '800px',
                        // Increase the height for thickness
                        bgcolor: 'gray',
                        opacity: 0.75,
                        borderRadius: '4px',
                    }}
                />
            </div>

            {/* Customer Reviews Section */}
            <div className='text-center mt-8 '>
                <p className='text-xl text-gradient'>MY EXPERTISE</p>
                <p className='text-4xl font-bold text-white '>Customers Feedback</p>
            </div>

            <div className='mt-10'>
                <div ref={sliderRef} className="keen-slider text-white ">
                    <div className="keen-slider__slide flex flex-col items-center justify-center">
                        <div className='w-[200px] lg:w-[300px] h-[200px] lg:h-[300px] bg-blue-700 rounded-full flex items-center justify-center overflow-hidden'>
                            <img className='w-[250px] mt-12 object-cover' src={review1} alt='Top orion' />
                        </div>
                        <p className='w-[550px]'>Top Orion exceeded my expectations. The website they developed for my business is fast, responsive, and visually stunning. Their attention to detail is top-notch.</p>
                        <p>"Jhon Kosel"</p>
                    </div>
                    <div className="keen-slider__slide flex flex-col items-center justify-center">
                        <div className='w-[200px] lg:w-[300px] h-[200px] lg:h-[300px] bg-yellow-600 rounded-full flex items-center justify-center overflow-hidden'>
                            <img className=' object-cover mt-12' src={review2} alt='Top orion' />
                        </div>
                        <p className='w-[550px]'>Working with Top Orion was a great experience. They delivered a custom website that perfectly aligns with my brand. The entire process was smooth and professional.</p>
                        <p>"Michel S."</p>
                    </div>
                    <div className="keen-slider__slide flex flex-col items-center justify-center">
                        <div className='w-[200px] lg:w-[300px] h-[200px] lg:h-[300px] bg-red-600 rounded-full flex items-center justify-center overflow-hidden'>
                            <img className=' object-cover' src={review3} alt='Top orion' />
                        </div>
                        <p className='w-[550px]'>I'm thrilled with the results from Top Orion. They took my ideas and turned them into a sleek, modern website that has already attracted new clients. Highly recommended!</p>
                        <p>"Meheta Shingh"</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CustomerReview;
