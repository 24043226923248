import React, { useMemo, useState } from 'react';
import UseAxiosPublic from '../../hook/UseAxiosPublic';
import { useQuery } from '@tanstack/react-query';
import { Link } from 'react-router-dom';

const Search = () => {
    const axiosPublic = UseAxiosPublic()
    const [searchTerm, setSearchTerm] =useState('')
    const { data: items = [], refetch } = useQuery({
        queryKey: ['items'],
        queryFn: async () => {
            const res = await axiosPublic.get(`/blogs?search=${searchTerm}`)
            return res.data;
        }
    })

    const handleSearch = (e) => {
        e.preventDefault()
        setSearchTerm(e.target.value)
        refetch()

    }

    // Count categories for filtering
    const categoryCounts = useMemo(() => {
        const counts = items.reduce((acc, item) => {
            const category = item.category || "Others";
            if (!acc[category]) {
                acc[category] = 0;
            }
            acc[category]++;
            return acc;
        }, {});
        counts["All"] = items.length;
        return counts;
    }, [items]);

    // Sort blogs by publish date
    const sortedBlogs = useMemo(() => {
        return [...items].sort((a, b) => new Date(b.Publish_date) - new Date(a.Publish_date));
    }, [items]);

    return (
        <div>
             <div className=' '>
                    {/* Search input */}
                    <p>SEARCH</p>
                    <input
                        type="text"
                        placeholder="Search"
                        onChange={handleSearch}
                        className="border-2 p-2"

                    />
                    {/* Categories */}
                    <div className="py-4 text-xl">
                        <p className="text-base mb-2 mt-4">CATEGORIES</p>
                        <div className="flex justify-between">
                            <p>All</p>
                            <p>({categoryCounts["All"]})</p>
                        </div>
                        <hr />
                        {Object.keys(categoryCounts)
                            .filter((category) => category !== "All")
                            .map((category) => (
                                <div key={category} className="list-none">
                                    <div className=" flex justify-between">
                                        <div>{category}</div>{" "}
                                        <div>({categoryCounts[category]})</div>
                                    </div>
                                    <hr />
                                </div>
                            ))}
                    </div>
                    {/* Recent posts */}
                    <div>
                        <p className="text-xl font-semibold mb-2 mt-2">RECENT POSTS</p>
                        <div>
                            {sortedBlogs
                                .slice(0, 3)
                                .map((blog) => (
                                    <div key={blog._id} className="flex items-center mb-4">
                                        <div className="w-20 mr-4">
                                            <img src={blog.image} alt={blog.title} />
                                        </div>
                                        <Link to={`/blog/${blog._id}`}>
                                            <p className="text-base font-semibold">
                                                {blog.title}
                                            </p>
                                        </Link>
                                    </div>
                                ))}
                        </div>
                    </div>

                </div>
        </div>
    );
};

export default Search;