import React, { forwardRef } from 'react';
import { useForm } from 'react-hook-form';
import { MdEmail } from "react-icons/md";
import { FaLocationDot } from "react-icons/fa6";
import { FaHeadphones } from "react-icons/fa";
import { FaSkype } from "react-icons/fa";
import axios from 'axios';
import emailjs from 'emailjs-com';

const Contact = forwardRef((props, ref) => {
    const { register, handleSubmit, formState: { errors },reset } = useForm();

    const onSubmit = data => {
        console.log(data);
        emailSend(data);
        axios.post('https://backend-delta-flame-16.vercel.app/contacts',data)
        .then(response => {
            console.log(response);
            alert('Contact submitted successfully');
            reset();
        })
    };
    const emailSend = (data) => {
        console.log(data);
        const emailInfo = {
            name: data.name,
            email: data.email,
            phone: data.phone,
            message: data.message
        }
        emailjs.send('service_3d7wl6s', 'template_oxqog3a', emailInfo, 'IQMDrJD8MHbEC2oKr')
            .then((response) => {
                console.log('Email sent successfully:', response.status, response.text);
            })
            .catch((error) => {
                console.error('Error sending email:', error);
            });
    }

    return (
        <div ref={ref} className='max-w-6xl lg:max-w-5xl xl:max-w-6xl 2xl:max-w-7xl mx-auto text-white px-4 lg:px-8 mt-20 overflow-x-hidden'>
            <div className='flex justify-between items-center'>
                <div className="flex justify-center items-center p-8">
                    <form
                        onSubmit={handleSubmit(onSubmit)}
                        className=" p-6 rounded-lg shadow-lg w-full max-w-lg space-y-4 bg-black bg-opacity-30"
                    >
                        <p className='text-4xl font-bold text-white'>Let's talk?</p>
                        <p className='text-base text-white'>We are here to help you, make your think to real</p>
                        <div className="space-y-2">
                            <label className="block text-gray-700">Name</label>
                            <input
                                type="text"
                                {...register('name', { required: true })}
                                className="w-full p-2 border border-gray-300 rounded bg-black bg-opacity-30"
                            />
                            {errors.name && <span className="text-red-500">Name is required</span>}
                        </div>

                        <div className='flex gap-6'>
                            <div className="space-y-2">
                                <label className="block text-gray-700">Email</label>
                                <input
                                    type="email"
                                    {...register('email', { required: true })}
                                    className="w-full p-2 border border-gray-300 rounded bg-black bg-opacity-30"
                                />
                                {errors.email && <span className="text-red-500">Email is required</span>}
                            </div>

                            <div className="flex space-x-4">
                                <div className="flex-1 space-y-2">
                                    <label className="block text-gray-700">Phone</label>
                                    <input
                                        type="text"
                                        {...register('phone', { required: true })}
                                        className="w-full p-2 border border-gray-300 rounded bg-black bg-opacity-30"
                                    />
                                    {errors.phone && <span className="text-red-500">Phone is required</span>}
                                </div>
                            </div>
                        </div>

                        <div className="space-y-2">
                            <label className="block text-gray-700">Message</label>
                            <textarea
                                {...register('message', { required: true })}
                                className="w-full p-2 border border-gray-300 rounded bg-black bg-opacity-30"
                                rows="4"
                            ></textarea>
                            {errors.message && <span className="text-red-500">Message is required</span>}
                        </div>

                        <button
                            type="submit"
                            className="w-full bg-blue-500 text-white p-2 rounded hover:bg-blue-600"
                        >
                            Submit
                        </button>
                    </form>
                </div>
                <div>
                    <div className='flex items-center gap-2'>
                        <MdEmail className='text-6xl text-blue-600' />
                        <div className='mb-4'>
                            <p>OUR EMAIL</p>
                            <p>admin@toporion.net</p>
                            <p>support@toporion.net</p>
                        </div>
                    </div>

                    <div className='flex items-center gap-2'>
                        <FaLocationDot className='text-6xl text-red-600' />
                        <div className='mb-4'>
                            <p>Our Address</p>
                            <p>1.ka,162/2, uttara, Dhaka</p>
                            <p>2. sherpur, Mymensingh, Bangladesh</p>
                        </div>
                    </div>
                    <div className='flex items-center gap-2'>
                        <FaHeadphones className='text-6xl text-white' />
                        <div className='mb-4'>
                            <p>Our Phones</p>
                            <p>+880-1752-529-602</p>
                            <p>+880-1972-529-602</p>
                        </div>
                    </div>
                    <div className='flex items-center gap-2'>
                        <FaSkype className='text-6xl  text-yellow-400' />
                        <div>
                            <p>Skype</p>
                            <p>salman.rokon</p>
                           
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
});

export default Contact;
