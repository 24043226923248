import React from 'react';
import { MdDashboard } from 'react-icons/md';
import { Link, Outlet } from 'react-router-dom';
import { RiAccountPinCircleFill } from "react-icons/ri";
import { RiProjectorFill } from "react-icons/ri";
import { FaUsers } from 'react-icons/fa';

const Dashboard = () => {
    return (
        <div className='bg-slate-300 w-full min-h-screen'>
      
            <div className='h-screen fixed md:w-64  bg-gradient-to-t from-slate-700 via-sky-500 to-slate-800'>
                <ul className='space-y-4 text-xl font-bold text-white mt-4'>
                    <li>
                        <Link>
                            <i className='fas fa-tachometer-alt text-white'></i>
                            <span className='ml-3 flex gap-2 '><MdDashboard className='text-pink-500 font-bold text-2xl' /> Dashboard</span>
                        </Link>
                    </li>
                    <li>
                        <Link>
                            <i className='fas fa-tachometer-alt text-white'></i>
                            <span className='ml-3 flex gap-2'> <RiAccountPinCircleFill className='text-pink-500 font-bold text-2xl'/> My Account</span>
                        </Link>
                    </li>
                    <li>
                        <Link>
                            <i className='fas fa-tachometer-alt text-white'></i>
                            <span className='ml-3 flex gap-2'><RiProjectorFill className='text-pink-500 font-bold text-2xl'/> Project</span>
                        </Link>
                    </li>
                    <li>
                        <Link>
                            <i className='fas fa-tachometer-alt text-white'></i>
                            <span className='ml-3 flex gap-2'><FaUsers className='text-pink-500 font-bold text-2xl' /> Total Users</span>
                        </Link>
                    </li>
                </ul>
            </div>
            <div >
                <Outlet />
            </div>
        </div>
    );
};

export default Dashboard;