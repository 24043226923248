

const Constraction = () => {
    return (
        <div className="flex items-end justify-center">
            <p className="text-7xl font-bold text-center">Under Construction</p>
        </div>
    );
};

export default Constraction;