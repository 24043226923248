import React from 'react';
import { FaFacebook, FaInstagram, FaLinkedin, FaPinterest, FaTwitter, FaYoutube } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <div className='bg-[#003d63] text-white flex flex-row justify-between p-8 items-center overflow-hidden'>
            <div className='flex flex-1 lg:flex-row gap-16'>
                <div> <Link to="https://www.linkedin.com/in/toporionbd/" target='/'><FaLinkedin className='text-4xl'/></Link></div>
                <div><FaFacebook className='text-4xl'/></div>
                <div><Link to="https://www.instagram.com/salmanrokon/" target='/'> <FaInstagram className='text-4xl'/></Link></div>
                <div><Link to="https://www.pinterest.com/toporionbd/" target='/'> <FaPinterest className='text-4xl'/></Link></div>
                <div><Link to="https://www.youtube.com/@TopOrionBD" target='/'><FaYoutube className='text-4xl'/></Link></div>
                <div> <Link to="https://x.com/toporionbd" target='/'><FaTwitter className='text-4xl'/></Link></div>
               
                
               
               
                  
               
            </div>
            <div>
                <p>© 2024 Top Orion. All Rights Reserved</p>
            </div>
        </div>
    );
};

export default Footer;