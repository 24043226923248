import { createUserWithEmailAndPassword, onAuthStateChanged, signInWithEmailAndPassword, signOut } from 'firebase/auth';
import React, { createContext, useEffect } from 'react';
import auth from '../firbase/firebase.config';
import UseAxiosPublic from '../hook/UseAxiosPublic';


export const AuthContext=createContext(null)
const AuthProvider = ({children}) => {
    const [user,setUser]=React.useState(null)
    const [loading,setLoading]=React.useState(true)
    const axiosPublic=UseAxiosPublic();

    const createUser=(email,password)=>{
        setLoading(true)
        return createUserWithEmailAndPassword(auth,email,password)
    }

    const loginUser=(email,password)=>{
        setLoading(true)
        return signInWithEmailAndPassword(auth,email,password)
    }

    const logOutUser=()=>{
        setLoading(true)
        return signOut(auth)
    }

    useEffect(()=>{
        const unsubscribe =onAuthStateChanged(auth,currentUser=>{
            setUser(currentUser)
         
            setLoading(false)
        })
        return()=>{
            unsubscribe()
        }
    },[axiosPublic])

    

    const authInfo={
        user,loading,createUser,loginUser,logOutUser
    }

    return (
        <AuthContext.Provider value={authInfo}>
            {children}
        </AuthContext.Provider>
    );
};

export default AuthProvider;