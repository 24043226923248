import axios from 'axios';

const UseAxiosSecure = () => {
    const axiosSecure=axios.create({
        baseURL: 'https://portbackend-roan.vercel.app',
        // Add any necessary headers or interceptors here
    })
    return axiosSecure;
};

export default UseAxiosSecure;