import React from 'react';
import { useForm } from 'react-hook-form';
import 'react-quill/dist/quill.snow.css'; // Import styles for the text editor
import ReactQuill from 'react-quill';
import UseAxiosPublic from '../../../hook/UseAxiosPublic';
import UseAxiosSecure from '../../../hook/UseAxiosSecure';

const image_hosting_key = '98e0ae8f20d1a2742367d0a3ddcb225f';
const image_hosting_api = `https://api.imgbb.com/1/upload?key=${image_hosting_key}`;

const Post = () => {
    const axiosPublic = UseAxiosPublic();
    const axiosSecure = UseAxiosSecure();
    const { register, handleSubmit, setValue, getValues, formState: { errors } } = useForm();

    const onSubmit = data => {
        console.log(data);
        const imageFile = { image: data.image[0] };
        axiosPublic.post(image_hosting_api, imageFile, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then(res => {
                console.log(res.data);
                if (res.data.success) {
                    const info = {
                        title: data.title,
                        publish_date: data.publish_date,
                        publish_by: data.publish_by,
                        like_count: data.like_count,
                        category: data.category,
                        image: res.data.data.display_url,
                        details: data.details,
                        keywords: data.keywords, 
                        meta_description: data.meta_description 
                    };
                    axiosSecure.post('/blogs', info)
                        .then(res => {
                            console.log(res.data);
                            alert('Blog Posted Successfully');
                        });
                }
            });
    };

    React.useEffect(() => {
        register('details');
    }, [register]);

    const modules = {
        toolbar: [
            [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
            [{size: []}],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{'list': 'ordered'}, {'list': 'bullet'}, 
             {'indent': '-1'}, {'indent': '+1'}],
            ['link', 'image', 'video'],
            [{ 'color': [] }, { 'background': [] }], // Text color and background color
            ['clean']                                        
        ],
    };

    const formats = [
        'header', 'font', 'size',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'indent',
        'link', 'image', 'video',
        'color', 'background' // Formats for text color and background color
    ];

    return (
        <div className='py-8'>
            <div className="max-w-4xl mx-auto p-6 bg-white shadow-md rounded-lg">
                <form onSubmit={handleSubmit(onSubmit)} className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    <div>
                        <label className="block text-sm font-medium text-gray-700">Title</label>
                        <input
                            {...register('title', { required: true })}
                            className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm"
                            type="text"
                        />
                        {errors.title && <span className="text-red-500 text-sm">Title is required</span>}
                    </div>

                    <div>
                        <label className="block text-sm font-medium text-gray-700">Publish Date</label>
                        <input
                            {...register('publish_date', { required: true })}
                            className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm"
                            type="date"
                        />
                        {errors.publish_date && <span className="text-red-500 text-sm">Publish date is required</span>}
                    </div>

                    <div>
                        <label className="block text-sm font-medium text-gray-700">Published By</label>
                        <input
                            {...register('publish_by', { required: true })}
                            className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm"
                            type="text"
                        />
                        {errors.publish_by && <span className="text-red-500 text-sm">Publisher name is required</span>}
                    </div>

                    <div>
                        <label className="block text-sm font-medium text-gray-700">Keywords</label>
                        <input
                            {...register('keywords', { required: true })}
                            className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm"
                            type="text"
                        />
                        {errors.keywords && <span className="text-red-500 text-sm">Keywords are required</span>}
                    </div>

                    <div>
                        <label className="block text-sm font-medium text-gray-700">Meta Description</label>
                        <textarea
                            {...register('meta_description', { required: true })}
                            className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm"
                        />
                        {errors.meta_description && <span className="text-red-500 text-sm">Meta description is required</span>}
                    </div>

                    <div>
                        <label className="block text-sm font-medium text-gray-700">Like Count</label>
                        <input
                            {...register('like_count')}
                            className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm"
                            type="number"
                        />
                    </div>

                    <div>
                        <label className="block text-sm font-medium text-gray-700">Category</label>
                        <select
                            {...register('category')}
                            className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm"
                        >
                            <option value="web development">Web Development</option>
                            <option value="web design">Web Design</option>
                            <option value="tour">Tour</option>
                            <option value="entertainment">Entertainment</option>
                            <option value="science">Science</option>
                        </select>
                    </div>

                    <div>
                        <label className="block text-sm font-medium text-gray-700">Image Upload</label>
                        <input
                            {...register('image')}
                            className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm"
                            type="file"
                        />
                    </div>

                    <div className="col-span-1 md:col-span-2">
                        <label className="block text-sm font-medium text-gray-700">Details</label>
                        <ReactQuill
                            value={getValues('details')}
                            onChange={(content) => setValue('details', content)}
                            modules={modules}
                            formats={formats}
                            className="mt-1"
                        />
                    </div>

                    <div className="col-span-1 md:col-span-2">
                        <button type="submit" className="w-full p-3 bg-blue-600 text-white rounded-md shadow-md hover:bg-blue-700">
                            Submit
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default Post;
