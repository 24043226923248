import React from 'react';
import { useForm } from 'react-hook-form';
import horseBg from '../../assets/horseBg.jpg';
import { Link, useNavigate } from 'react-router-dom';
import UseAuth from '../../hook/UseAuth';
import UseAxiosPublic from '../../hook/UseAxiosPublic';
import Swal from 'sweetalert2';

const Register = () => {
    const { register, handleSubmit, formState: { errors } } = useForm();
    const{createUser}=UseAuth();
    const axiosPublic=UseAxiosPublic()
    const navigate=useNavigate()

    const onSubmit = (data) => {
        console.log(data);
        createUser(data.email,data.password)
        .then(res=>{
            const userInfo={
                name: data.name,
                email: data.email,
                password: data.password,
               
            }
            axiosPublic.post('/users',userInfo)
            .then(res=>{
                console.log(res.data);
                Swal.fire("Registration successful!");
                navigate('/')
            })
        })
    };

    return (
        <div
            className='w-full h-screen flex justify-center items-center relative'
            style={{ backgroundImage: `url(${horseBg})`, backgroundSize: 'cover', backgroundPosition: 'center' }}
        >
            {/* Overlay */}
            <div className='absolute w-full h-full bg-black opacity-20 top-0 left-0 z-10'></div>

            {/* Home button */}
            <Link to='/'>
                <button
                    className='absolute top-5 right-5 p-3 w-[150px] text-white font-semibold rounded-lg border-2 border-transparent hover:bg-white hover:text-black transition duration-300 home-button cursor-pointer z-20'
                >
                    Home
                </button>
            </Link>
            <div className='w-full max-w-2xl p-8 bg-white bg-opacity-10 backdrop-blur-md rounded-lg shadow-lg border border-white border-opacity-30 z-20 '>
                <h2 className='text-3xl font-semibold text-white mb-6 text-center'>Register</h2>
                <form onSubmit={handleSubmit(onSubmit)} className='space-y-6'>
                    <div>
                        <label className='block text-white text-opacity-80 font-medium mb-2'>Name</label>
                        <input
                            type='text'
                            {...register('name', { required: 'Name is required' })}
                            className='w-full p-3 bg-white bg-opacity-20 text-white placeholder-white placeholder-opacity-50 border border-white border-opacity-20 rounded focus:outline-none focus:ring-2 focus:ring-white focus:ring-opacity-40'
                            placeholder='Enter your name'
                        />
                        {errors.name && <p className='text-red-500 text-sm mt-2'>{errors.name.message}</p>}
                    </div>
                    <div>
                        <label className='block text-white text-opacity-80 font-medium mb-2'>Email</label>
                        <input
                            type='email'
                            {...register('email', { required: 'Email is required' })}
                            className='w-full p-3 bg-white bg-opacity-20 text-white placeholder-white placeholder-opacity-50 border border-white border-opacity-20 rounded focus:outline-none focus:ring-2 focus:ring-white focus:ring-opacity-40'
                            placeholder='Enter your email'
                        />
                        {errors.email && <p className='text-red-500 text-sm mt-2'>{errors.email.message}</p>}
                    </div>
                    <div>
                        <label className='block text-white text-opacity-80 font-medium mb-2'>Password</label>
                        <input
                            type='password'
                            {...register('password', { required: 'Password is required' })}
                            className='w-full p-3 bg-white bg-opacity-20 text-white placeholder-white placeholder-opacity-50 border border-white border-opacity-20 rounded focus:outline-none focus:ring-2 focus:ring-white focus:ring-opacity-40'
                            placeholder='Enter your password'
                        />
                        {errors.password && <p className='text-red-500 text-sm mt-2'>{errors.password.message}</p>}
                    </div>
                    <button
                        type='submit'
                        className='w-full p-3 bg-white bg-opacity-30 text-white font-semibold rounded hover:bg-opacity-40 focus:outline-none focus:ring-2 focus:ring-white focus:ring-opacity-40'
                    >
                        Register
                    </button>
                    <p className='text-white'>Already have an account? <Link to="/login"><span className='text-rose-500 font-extrabold'>Login</span></Link> </p>
                </form>
            </div>
        </div>
    );
};

export default Register;
