import * as React from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import SubComp from '../subComp/SubComp';
import Skills from '../skills/Skills';
import Education from '../education/Education';

const Resume = () => {
    const [value, setValue] = React.useState('1');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div className='max-w-6xl lg:max-w-5xl xl:max-w-6xl 2xl:max-w-7xl mx-auto text-white px-4 lg:px-8 mt-16'>
            <Box
                sx={{
                    width: '100%',
                    typography: 'body1',
                    backdropFilter: 'blur(10px)', // Glass effect
                    backgroundColor: 'rgba(255, 255, 255, 0.1)', // Slightly transparent background
                    borderRadius: '16px',
                    padding: '16px',
                    border: '1px solid rgba(255, 255, 255, 0.1)',
                }}
            >
                <TabContext value={value}>
                    <Box
                        sx={{
                            borderBottom: 1,
                            borderColor: 'divider',
                            maxWidth: 'max-w-4xl',
                            mx: 'auto', // Center align
                        }}
                    >
                        <TabList
                            onChange={handleChange}
                            aria-label="lab API tabs example"
                            className='bg-slate-400 flex justify-between rounded-full w-4/5 mx-auto -mt-8'
                            TabIndicatorProps={{
                                style: {
                                    backgroundColor: 'red', // Red indicator color
                                },
                            }}
                        >
                            <Tab
                                label="Biography"
                                value="1"
                                sx={{
                                    flexGrow: 1, // Equal width
                                    textAlign: 'center',
                                    '&:hover': {
                                        color: 'white', // Hover effect
                                        backgroundColor: 'red', // Hover background color
                                    },
                                }}
                            />
                            <Tab
                                label="Skills"
                                value="2"
                                sx={{
                                    flexGrow: 1, // Equal width
                                    textAlign: 'center',
                                    '&:hover': {
                                        color: 'white', // Hover effect
                                        backgroundColor: 'red', // Hover background color
                                    },
                                }}
                            />
                            <Tab
                                label="Education"
                                value="3"
                                sx={{
                                    flexGrow: 1, // Equal width
                                    textAlign: 'center',
                                    '&:hover': {
                                        color: 'white', // Hover effect
                                        backgroundColor: 'red', // Hover background color
                                    },
                                }}
                            />
                        </TabList>
                    </Box>
                    <Box sx={{ maxWidth: 'max-w-6xl', mx: 'auto', mt: 4 }}>
                        <TabPanel
                            value="1"
                            sx={{
                                backgroundColor: 'transparent', // Transparent background
                                opacity: '.5px',
                                borderRadius: '8px', // Optional: Add border radius
                                padding: '16px',
                            }}
                        >
                           <SubComp/>
                        </TabPanel>
                        <TabPanel
                            value="2"
                            sx={{
                                backgroundColor: 'transparent', // Transparent background

                                borderRadius: '8px', // Optional: Add border radius
                                padding: '16px',
                            }}
                        >
                            <Skills/>
                        </TabPanel>
                        <TabPanel
                            value="3"
                            sx={{
                                backgroundColor: 'transparent', // Transparent background
                                // Border
                                borderRadius: '8px', // Optional: Add border radius
                                padding: '16px',
                            }}
                        >
                            <Education/>
                        </TabPanel>
                    </Box>
                </TabContext>
            </Box>
        </div>
    );
};

export default Resume;
