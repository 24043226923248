import { Divider } from '@mui/material';
import React from 'react';

const SubComp = () => {
    return (
        <div>
            <div className='flex justify-between w-full gap-16'>
                <div className='w-1/2'>
                    <span className='flex justify-between mb-2'>
                        <p>Name</p>
                        <p>Salman Haider</p>
                    </span>
                    <Divider sx={{ width: '100%', borderColor: 'rgba(255, 255, 255, 0.3)' }} />
                    <span className='flex justify-between mb-2'>
                        <p>Age</p>
                        <p>35</p>
                    </span>
                    <Divider sx={{ width: '100%', borderColor: 'rgba(255, 255, 255, 0.3)' }} />
                    <span className='flex justify-between mb-2'>
                        <p>email</p>
                        <p>admin@toporion.net</p>
                    </span>
                    <Divider sx={{ width: '100%', borderColor: 'rgba(255, 255, 255, 0.3)' }} />
                    <span className='flex justify-between mb-2'>
                        <p>Skype</p>
                        <p>salman.rokon</p>
                    </span>
                    <Divider sx={{ width: '100%', borderColor: 'rgba(255, 255, 255, 0.3)' }} />
                </div>

                <div className='w-1/2'>
                    <div>
                        <span className='flex justify-between mb-2'>
                            <p>Nationality</p>
                            <p>Bangladeshi</p>
                        </span>
                        <Divider sx={{ width: '100%', borderColor: 'rgba(255, 255, 255, 0.3)' }} />
                        <span className='flex justify-between mb-2'>
                            <p>Address</p>
                            <p>ka,160/2,Khilkhet,Dhaka</p>
                        </span>
                        <Divider sx={{ width: '100%', borderColor: 'rgba(255, 255, 255, 0.3)' }} />
                        <span className='flex justify-between mb-2'>
                            <p>Phone</p>
                            <p>+8801-752-529-602</p>
                        </span>
                        <Divider sx={{ width: '100%', borderColor: 'rgba(255, 255, 255, 0.3)' }} />
                        <span className='flex justify-between mb-2'>
                            <p>Freelance</p>
                            <p>Available</p>
                        </span>
                        <Divider sx={{ width: '100%', borderColor: 'rgba(255, 255, 255, 0.3)' }} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SubComp;